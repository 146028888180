<template>

  <!-- <transition-group name="fade"> -->

  <component
    :is="component.component"
    v-for="component of parts"
    :id="component.data?.slug"
    :key="component.data?.id"
    :payload="component.data"
    class="fade-item"
  >
    <h2>Component «{{ component.component }}» not registered.</h2>
  </component>
  <Footer key="footer" class="fade-item" />
  <!-- </transition-group> -->

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    AboutBlock: defineAsyncComponent(() => import('@/components/parts/AboutBlock.vue')),
    HeaderSubpage: defineAsyncComponent(() => import('@/components/parts/HeaderSubpage.vue')),
    Hero: defineAsyncComponent(() => import('@/components/parts/Hero.vue')),
    HomepageIntrotext: defineAsyncComponent(() => import('@/components/parts/HomepageIntrotext.vue')),
    Kontaktblock: defineAsyncComponent(() => import('@/components/parts/Kontaktblock.vue')),
    News: defineAsyncComponent(() => import('@/components/parts/News.vue')),
    Newspage: defineAsyncComponent(() => import('@/components/pages/Newspage.vue')),
    ServicesList: defineAsyncComponent(() => import('@/components/parts/ServicesList.vue')),
    ServicesTeaser: defineAsyncComponent(() => import('@/components/parts/ServicesTeaser.vue')),
    Team: defineAsyncComponent(() => import('@/components/parts/Team.vue')),
    Textblock: defineAsyncComponent(() => import('@/components/parts/Textblock.vue')),
    TopicsTeaser: defineAsyncComponent(() => import('@/components/parts/TopicsTeaser.vue')),

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
