<template>
  <div class="Language">
    <transition-group tag="ul" name="list" class="inner flex flex-col">
      <li
        v-for="locale in $store.state.router.locales"
        :key="locale"
        class="lang"
        :class="{active: locale === currentLocale}"
      >
        <a :href="getAlt(locale)" @click.prevent="swap(locale)">{{ locale }}</a>
      </li>
    </transition-group>
  </div>
</template>

<script>
export default {
  computed: {
    currentLocale() {
      return this.$store.state.router.locale;
    },
  },
  methods: {
    swap(locale) {
      this.$store.dispatch('router/push', { uri: this.getAlt(locale) });
    },
    getAlt(locale) {
      return this.$store.state.router.page.meta?.translatedPaths[locale];
    },
  },
};
</script>

<style scoped lang="scss">
.Language {
  position: relative;
  height: 35px;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;

  .lang.active a {
    font-weight: 600;
  }

  .inner {
    height: px(45);
    padding: 0 0.25rem;
    overflow: hidden;
    transition: height .1s ease-out, border-color .25s ease-out;
    border: 2px solid transparent;
    border-radius: px(25);

    li {
      display: inline-block;
      font-size: px(20);
      @screen md {
        font-size: px(16);
      }
      @screen lg {
        font-size: px(18);
      }
      &:first-child a {
        position: relative;
        opacity: 1;
        pointer-events: none;
        z-index: 1;
      }
    }
    a {
      font-size: px(20);
      // line-height: 1;
      opacity: 0;
      display: inline-block;
      transition: opacity 0.25s ease-out;
      @screen md {
        font-size: px(16);
      }
      @screen lg {
        font-size: px(18);
      }
    }
  }

  &:hover {
    .inner {
      height: 70px;
      border-color: var(--color-navy);
    }
    a {
      opacity: 1;
    }
  }

  @media (max-width: theme("screens.md")) {
    font-size: 0.8rem;
    margin-top: 0px;

    .inner {
      padding: 3px 4px;
    }

    &:hover {
      .inner {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
        // width: 92px;
      }
    }
  }
}

.list-move {
  transition: transform 0.3s cubic-bezier(0.59, 0.12, 0.34, 0.95);
}
</style>
