<template>
  <div class="subnavigation pt-8 md:pt-16">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1 flex flex-col md:flex-row md:items-center md:justify-between">
          <Go
            v-for="link in subNavigation?.links"
            :key="link.id"
            :to="link.uri"
            class="text-navy subnav-link mb-2 md:pb-2 self-start "
            :class="{active: link.uri === $store.state.router.page.meta?.uri}"
          >
            {{ link.title }}
          </Go>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    subNavigation() {
      return this.$store.state.router.layout?.subnavigation;
    },
  },
  watch: {
    subNavigation() {
      // console.log(this.subNavigation);
    },
  },
};
</script>

<style lang="scss" scoped>
.subnav-link {
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.25s ease-out;
  font-size: px(18);
  @screen sm {
    font-size: px(22);
  }
  @screen lg {
    font-size: px(26);
  }
  @screen xl {
    font-size: px(30);
  }
  @include hover {
    border-bottom: 2px solid var(--color-navy);
  }
  &.active {
    border-bottom: 2px solid var(--color-navy);
  }
}
</style>
