<template>
  <div id="nav" class="flex items-center py-4 md:py-6 bg-white">
    <div class="constrain flex items-center bg-white">

      <Go :to="'/' + $store.state.router.locale" aria-label="Home">
        <img class="logo" :src="$store.state.router.layout?.logo" alt="logo">
      </Go>

      <div class="relative flex items-center ml-auto">
        <nav class="hidden md:flex md:items-center">
          <!-- <transition-group name="default"> -->
          <Go
            v-for="link of navigation?.links"
            :key="link.uri"
            :to="link.uri"
            class="mx-4 mr-12 link text-navy uppercase"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
          <!-- </transition-group> -->
          <Language />
        </nav>
        <Hamburger
          class="md:hidden"
          :class="{ active: mobile }"
          @click="toggle()"
        />
      </div>

    </div>
  </div>

  <div class="movile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <!-- <transition-group name="appear"> -->
    <div v-if="mobile" class="mobile-nav">
      <Go
        v-for="link of navigation?.links"
        :key="link.id"
        :to="link.uri"
        class="px-4 link"
        :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
        @click="toggle(true)"
        v-text="link.title"
      />
      <Language class="pt-4" />
    </div>
    <!-- </transition-group> -->
  </div>
</template>

<script>

import Scrolllock from '@/utils/scrolllock';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Language,
    Hamburger,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout?.navigation;
    },
  },
  mounted() {
    // console.log(this.navigation);
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  @screen md {
    left: 50%;
    transform: translateX(-50%);
  }
}

.link {
  font-size: px(20);
  transition: background-size 0.25s ease-out;
  background-image: linear-gradient(var(--color-navy), var(--color-navy));
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 0 2px;
  will-change: background-size;
  @screen md {
    font-size: px(16);
  }
  @screen lg {
    font-size: px(18);
  }
  &.active {
    background-size: 100% 2px;
  }
  @include hover {
    background-size: 100% 2px;
  }
}

.logo {
  width: auto;
  max-height: px(50);
  @screen md {
    max-height: px(60);
  }
  @screen lg {
    max-height: px(70);
  }
  @screen xl {
    max-height: px(80);
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    color: var(--color-navy);
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-bottom: .5rem;
    text-decoration: none !important;
  }
}
</style>
