<template>

  <div id="overlay" />

  <Navigation />

  <main>
    <Subnavigation v-if="$store.state.router.page.meta?.hasSubnavigation" />
    <Dynamic v-if="content" :inject="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Subnavigation from '@/components/structure/Subnavigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {
    Subnavigation,
    Navigation,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },
  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
  },
  mounted() {
    // console.log(this.$store.state.router.page.meta?.hasSubnavigation);
  },
};
</script>
